import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ActivityLog } from "../gql/graphql";
import { ActivityLogFeedItem } from "./ActivityLogFeedItem";

const GET_PROFILE_ACTIVITY_FEED = gql`
  query GetProfileActivityFeed($username: String!) {
    getActivityLogFeed(request: { username: $username }) {
      activityLogID
      wordsWritten
      wordsDeleted
      timeElapsedSeconds
      wroteWithUsers {
        userID
        username
        profilePhotoURL
        profilePlaceholder
      }
      sessionStartTime
      createdAt
      title
      description
      userMetadata {
        userID
        username
        profilePhotoURL
        profilePlaceholder
      }
    }
  }
`;

function ProfileActivityFeed() {
  const { username } = useParams();

  const [profileActivityFeedData, setProfileActivityFeedData] = useState<
    ActivityLog[]
  >([]);
  const [getProfileActivityFeed] = useLazyQuery(GET_PROFILE_ACTIVITY_FEED, {
    variables: { username },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (username) {
      getProfileActivityFeed({ variables: { username } }).then((data) => {
        if (data.data?.getActivityLogFeed) {
          setProfileActivityFeedData(data.data.getActivityLogFeed);
        }
      });
    }
  }, [username, getProfileActivityFeed]);

  return (
    <div
      className="flex flex-col relative"
      style={{
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      {profileActivityFeedData.length === 0 ? (
        <div className="w-full flex flex-col items-start justify-center py-12 text-gray-500 font-sans">
          <p style={{ color: "#CCCCCC" }}>
            Write something to see your activity here!
          </p>
        </div>
      ) : (
        profileActivityFeedData.map((activityLog: ActivityLog) => (
          <div key={activityLog.activityLogID} style={{ marginBottom: 16 }}>
            <ActivityLogFeedItem
              key={activityLog.activityLogID}
              activityLog={activityLog}
            />
          </div>
        ))
      )}
    </div>
  );
}

export default ProfileActivityFeed;
