import { gql, useMutation } from "@apollo/client";
import { Button } from "@nextui-org/react";
import { Tooltip } from "./primitives/Tooltip";

import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
  Call,
  useCall,
  MicrophoneManager,
  createSoundDetector,
} from "@stream-io/video-react-sdk";

import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { useParams } from "react-router-dom";
import { HeadphonesIcon, Mic, MicOff, X } from "lucide-react";

const JOIN_VOICE_CALL = gql`
  mutation JoinVoiceCall($roomID: String!) {
    joinVoiceCall(request: { roomID: $roomID }) {
      token
      roomCallID
    }
  }
`;

const LEAVE_VOICE_CALL = gql`
  mutation LeaveVoiceCall($roomID: String!) {
    leaveVoiceCall(request: { roomID: $roomID }) {
      success
    }
  }
`;

const CallUI = memo(
  ({
    setMicrophoneReference,
    setIsMicActive,
    isMicActive,
  }: {
    setMicrophoneReference: (microphone: MicrophoneManager) => void;
    setIsMicActive: (isMicActive: boolean) => void;
    isMicActive: boolean;
  }) => {
    const call = useCall();
    const { useCallSettings, useParticipants, useRemoteParticipants } =
      useCallStateHooks();
    const settings = useCallSettings();
    const { useMicrophoneState } = useCallStateHooks();
    const { isEnabled, mediaStream, microphone, isMute, status } =
      useMicrophoneState();
    const { useSpeakerState } = useCallStateHooks();
    const { speaker, selectedDevice, devices } = useSpeakerState();
    const participants = useRemoteParticipants();
    const [audioLevel, setAudioLevel] = useState(100);

    console.log("status", status);

    useEffect(() => {
      microphone.stopOnLeave = true;
      setMicrophoneReference(microphone);
    }, [microphone, setMicrophoneReference]);

    useEffect(() => {
      if (isEnabled !== isMicActive) {
        setIsMicActive(isEnabled);
      }
    }, [isEnabled, isMicActive, setIsMicActive]);

    // useEffect(() => {
    //   console.log(speaker.state);
    //   console.log(speaker.state.volume);
    //   if (speaker && speaker.state.volume !== 0.9) {
    //     // speakers
    //     speaker.select(
    //       "0a810bad123ec29ab4cdcb6e2a4f9dc1815a027c6d6d329827d525a1cddb9026"
    //     );
    //     speaker.setVolume(0.9);
    //     console.log("set speaker volume to 0.9");
    //     console.log(speaker.state);
    //   }
    // }, [speaker]);

    useEffect(() => {
      // if (!isEnabled || !mediaStream) return;
      if (!mediaStream) {
        setAudioLevel(0);
        return;
      }
      const disposeSoundDetector = createSoundDetector(
        mediaStream,
        ({ audioLevel: al }) => setAudioLevel(al),
        { detectionFrequencyInMs: 20, destroyStreamOnStop: false }
      );
      return () => {
        disposeSoundDetector().catch(console.error);
      };
    }, [isEnabled, mediaStream]);

    useEffect(() => {
      if (!call) return;

      // Keep track of created audio elements
      const audioElements: HTMLAudioElement[] = [];

      // uncomment to play audio
      for (const participant of participants) {
        const audioElement = document.createElement("audio");
        audioElement.style.display = "none";
        document.body.appendChild(audioElement);
        call.bindAudioElement(audioElement, participant.sessionId);
        audioElements.push(audioElement);
      }

      // Cleanup function
      return () => {
        audioElements.forEach((element) => {
          document.body.removeChild(element);
        });
      };
    }, [participants, call]);

    return (
      <div
        className="flex flex-row items-center h-full"
        style={{
          width: 100,
          flex: "1",
          background: "#fff",
          borderRadius: "4px",
          marginLeft: "4px",
          marginRight: "50px",
          overflow: "hidden",
          position: "relative",
          height: "5px",
        }}
      >
        <div
          style={{
            clipPath: `inset(0 ${100 - audioLevel}% 0 0 round 2.5px)`,
            background: `linear-gradient(to right, #456577, #E5C852)`,
            width: "100%",
            height: "5px",
            position: "absolute",
          }}
        />
      </div>
    );
    // return <></>;
  }
);

const ActiveVoiceCallDisplay = memo(
  ({
    setMicrophoneReference,
    setIsMicActive,
    isMicActive,
    setCall,
    call,
  }: {
    setMicrophoneReference: (microphone: MicrophoneManager) => void;
    setIsMicActive: (isMicActive: boolean) => void;
    isMicActive: boolean;
    setCall: (call: Call | null) => void;
    call: Call | null;
  }) => {
    const { userID } = useUserInfo();
    const { roomID } = useParams();

    const [client, setClient] = useState<StreamVideoClient | null>(null);
    const attemptedJoinRef = useRef(false);
    const succesfullyJoinedRef = useRef(false);

    const [joinVoiceCall, { data }] = useMutation(JOIN_VOICE_CALL);
    const joinCall = useCallback(async () => {
      const { data } = await joinVoiceCall({
        variables: {
          roomID,
        },
      });

      const token = data.joinVoiceCall.token;
      const roomCallID = data.joinVoiceCall.roomCallID;
      if (token && userID) {
        const newClient = StreamVideoClient.getOrCreateInstance({
          apiKey: process.env.REACT_APP_STREAM_API_KEY!,
          token: token,
          user: {
            id: userID,
            type: "authenticated",
          },
        });
        if (newClient) {
          setClient(newClient);
        }
        if (newClient && roomCallID) {
          const call = newClient.call("audio_room", roomCallID);
          setCall(call);
          await call.join();
          succesfullyJoinedRef.current = true;
        }
      }
    }, [joinVoiceCall, roomID, setCall, userID]);

    useEffect(() => {
      // Only attempt to join if we don't already have a call
      if (!attemptedJoinRef.current && !call) {
        attemptedJoinRef.current = true;
        joinCall();
      } else if (call) {
        // If we already have a call, just set up the client
        const setupExistingCall = async () => {
          const { data } = await joinVoiceCall({
            variables: {
              roomID,
            },
          });
          const token = data.joinVoiceCall.token;
          if (token && userID) {
            const newClient = StreamVideoClient.getOrCreateInstance({
              apiKey: process.env.REACT_APP_STREAM_API_KEY!,
              token: token,
              user: {
                id: userID,
                type: "authenticated",
              },
            });
            if (newClient) {
              setClient(newClient);
            }
          }
        };
        setupExistingCall();
      }
    }, [attemptedJoinRef, call, joinCall, joinVoiceCall, roomID, userID]);

    if (!client) {
      return <></>;
    }

    return (
      <StreamVideo client={client}>
        <StreamCall call={call ?? undefined}>
          <CallUI
            setMicrophoneReference={setMicrophoneReference}
            setIsMicActive={setIsMicActive}
            isMicActive={isMicActive}
          />
        </StreamCall>
      </StreamVideo>
    );
  }
);

export const VoiceCall = memo(({
  isActive,
  call,
  microphoneReference,
  isMicActive,
  onStateChange,
}: {
  isActive: boolean;
  call: Call | null;
  microphoneReference: MicrophoneManager | null;
  isMicActive: boolean;
  onStateChange: (state: {
    isActive: boolean;
    call: Call | null;
    microphoneReference: MicrophoneManager | null;
    isMicActive: boolean;
  }) => void;
}) => {
  const { roomID } = useParams();
  const { isLoggedIn } = useUserInfo();
  const [leaveVoiceCall] = useMutation(LEAVE_VOICE_CALL);

  return (
    <div className="flex flex-row items-center">
      <Tooltip text={!isLoggedIn && !isActive ? "Login to join voice call" : isActive ? "Leave voice call" : "Join voice call"}>
        <Button
          onClick={async () => {
            if (isActive) {
              onStateChange({
                isActive: false,
                call: null,
                microphoneReference: null,
                isMicActive: false,
              });
              if (call) {
                await call.leave();
                await leaveVoiceCall({
                  variables: {
                    roomID,
                  },
                });
              }
            } else {
              onStateChange({
                ...{ isActive: true, call, microphoneReference, isMicActive },
              });
            }
          }}
          style={{ height: 20 }}
          isIconOnly
          isDisabled={!isLoggedIn && !isActive}
        >
          <p className="font-sans">
            {isActive ? <X size={16} /> : <HeadphonesIcon size={16} />}
          </p>
        </Button>
      </Tooltip>
      {isActive && (
        <Tooltip text={isMicActive ? "Mute microphone" : "Unmute microphone"}>
          <Button
            isIconOnly
            style={{ height: 20, marginLeft: "4px" }}
            onClick={async () => {
              if (microphoneReference) {
                await microphoneReference.toggle();
              }
            }}
          >
            {isMicActive ? <Mic size={16} /> : <MicOff size={16} />}
          </Button>
        </Tooltip>
      )}
      {isActive && (
        <ActiveVoiceCallDisplay
          setMicrophoneReference={(mic) =>
            onStateChange({ ...{ isActive, call, isMicActive }, microphoneReference: mic })
          }
          setCall={(newCall) =>
            onStateChange({ ...{ isActive, microphoneReference, isMicActive }, call: newCall })
          }
          call={call}
          isMicActive={isMicActive}
          setIsMicActive={(active) =>
            onStateChange({ ...{ isActive, call, microphoneReference }, isMicActive: active })
          }
        />
      )}
    </div>
  );
});
