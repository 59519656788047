import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
  text: string;
  active?: boolean;
  children: React.ReactNode;
  position?: 'top' | 'left' | 'right' | 'bottom';
}

export const Tooltip: React.FC<TooltipProps> = ({ text, active = true, children, position = 'top' }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [rect, setRect] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const childRef = useRef<HTMLDivElement>(null);

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  };

  const getTooltipStyle = (): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      visibility: active && isHovered ? 'visible' : 'hidden',
      position: 'fixed',
      padding: '8px',
      backgroundColor: '#333',
      color: 'white',
      borderRadius: '4px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 10000,
    };

    switch (position) {
      case 'left':
        return {
          ...baseStyle,
          top: `${rect.top + rect.height / 2}px`,
          right: `${window.innerWidth - rect.left + 8}px`,
          transform: 'translateY(-50%)',
        };
      case 'right':
        return {
          ...baseStyle,
          top: `${rect.top + rect.height / 2}px`,
          left: `${rect.left + rect.width + 8}px`,
          transform: 'translateY(-50%)',
        };
      case 'bottom':
        return {
          ...baseStyle,
          top: `${rect.top + rect.height + 8}px`,
          left: `${rect.left + rect.width / 2}px`,
          transform: 'translateX(-50%)',
        };
      default: // top
        return {
          ...baseStyle,
          bottom: `${window.innerHeight - rect.top + 8}px`,
          left: `${rect.left + rect.width / 2}px`,
          transform: 'translateX(-50%)',
        };
    }
  };

  const getArrowStyle = (): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      position: 'absolute',
      borderStyle: 'solid',
    };

    switch (position) {
      case 'left':
        return {
          ...baseStyle,
          top: '50%',
          left: '100%',
          marginTop: '-5px',
          borderWidth: '5px 0 5px 5px',
          borderColor: 'transparent transparent transparent #333',
        };
      case 'right':
        return {
          ...baseStyle,
          top: '50%',
          right: '100%',
          marginTop: '-5px',
          borderWidth: '5px 5px 5px 0',
          borderColor: 'transparent #333 transparent transparent',
        };
      case 'bottom':
        return {
          ...baseStyle,
          bottom: '100%',
          left: '50%',
          marginLeft: '-5px',
          borderWidth: '0 5px 5px 5px',
          borderColor: 'transparent transparent #333 transparent',
        };
      default: // top
        return {
          ...baseStyle,
          top: '100%',
          left: '50%',
          marginLeft: '-5px',
          borderWidth: '5px 5px 0 5px',
          borderColor: '#333 transparent transparent transparent',
        };
    }
  };

  useEffect(() => {
    if (childRef.current && isHovered) {
      const r = childRef.current.getBoundingClientRect();
      setRect(r);
    }
  }, [isHovered]);

  return (
    <div
      ref={childRef}
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      <div style={getTooltipStyle()} className="font-sans">
        {text}
        <div style={getArrowStyle()} />
      </div>
    </div>
  );
};

