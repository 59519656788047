import { ActivityLog } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import ProfileAvatar from "./ProfileAvatar";
import interpolate from "color-interpolate";
import { Tooltip } from "./primitives/Tooltip";
import { Link } from "react-router-dom";

const colormap = interpolate(["#E5C852", "#25536D"]);

function fadeToWhite(hexColor: string): string {
  const colorInterpolator = interpolate([hexColor, "#FFFFFF"]);
  return colorInterpolator(0.9); // 90% toward white
}

function getSundialScore(date: Date): number {
  // Convert to hours in local time (0-24)
  const hours = date.getHours() + date.getMinutes() / 60;

  // Shift hours to make 4am = 0
  let shiftedHours = hours - 4;
  if (shiftedHours < 0) shiftedHours += 24;

  // Map to -1 to 1 score over 24 hours
  // -1 = 4am, 0 = 4pm, 1 = 4am next day
  return shiftedHours / 12 - 1;
}

export function ActivityLogFeedItem({
  activityLog,
}: {
  activityLog: ActivityLog;
}) {
  const sessionDate = new Date(activityLog.sessionStartTime);
  const midpointDate = new Date(
    sessionDate.getTime() + (activityLog.timeElapsedSeconds * 1000) / 2
  );
  const sundialScore = getSundialScore(midpointDate);

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const timeString = isToday(sessionDate)
    ? sessionDate.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
    : sessionDate.toLocaleString(undefined, {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        month: "short",
        day: "numeric",
      });

  const otherCount = activityLog.wroteWithUsers?.length || 0;
  const othersText = otherCount === 1 ? "other" : "others";

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        background: "white",
        borderRadius: 16,
        padding: 24,
        marginBottom: 16,
        border: "3px solid transparent",
        backgroundImage: `linear-gradient(white, white), linear-gradient(to bottom, ${fadeToWhite(
          colormap(sundialScore)
        )}, ${colormap(sundialScore)})`,
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
        position: "relative",
      }}
    >
      <div
        className="font-sans"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "flex-start",
          }}
        >
          <ProfileAvatar
            src={activityLog.userMetadata.profilePhotoURL}
            profilePlaceholder={activityLog.userMetadata.profilePlaceholder}
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
            }}
          />
          <div>
            <div style={{ fontWeight: 500, fontSize: 12 }}>{activityLog.userMetadata.username}</div>
            <div style={{ color: "#666", fontSize: 12 }}>{timeString}</div>
          </div>
        </div>
        {activityLog.wroteWithUsers?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              {activityLog.wroteWithUsers?.map((user, index) => (
                <Tooltip text={user.username} key={user.userID}>
                  <Link to={`/writer/${user.username}`}>
                    <ProfileAvatar
                      src={user.profilePhotoURL || undefined}
                      profilePlaceholder={user.profilePlaceholder}
                      style={{
                        width: 18,
                        height: 18,
                        borderRadius: "50%",
                        marginLeft: index > 0 ? "-6px" : 0,
                        border: "1px solid white",
                        position: "relative",
                        zIndex: activityLog.wroteWithUsers!.length - index,
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Tooltip>
              ))}
            </div>
            <div
              style={{
                color: "#666",
                fontSize: 12,
              }}
            >
              wrote with {otherCount} {othersText}
            </div>
          </div>
        ) : null}
      </div>

      <p
        className="font-sans"
        style={{
          fontSize: 20,
          fontWeight: 600,
          margin: "24px 0 8px 0",
        }}
      >
        {activityLog.title}
      </p>

      <p
        className="font-sans"
        style={{
          margin: "0 0 24px 0",
          color: "#666",
          fontSize: 12,
        }}
      >
        {activityLog.description}
      </p>

      <div
        className="font-sans"
        style={{
          display: "flex",
          gap: 16,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div
            style={{
              background: "#4A7B5E",
              width: 120,
              height: 40,
              borderRadius: 10,
              color: "white",
              fontSize: 20,
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            +{activityLog.wordsWritten}
          </div>
          <div style={{ fontSize: 12, color: "#000" }}>words added</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div
            style={{
              background: "#C06B6B",
              width: 120,
              height: 40,
              borderRadius: 10,
              color: "white",
              fontSize: 20,
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            -{activityLog.wordsDeleted}
          </div>
          <div style={{ fontSize: 12, color: "#000" }}>words deleted</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div
            style={{
              background: "#8C8C8C",
              width: 120,
              height: 40,
              borderRadius: 10,
              color: "white",
              fontSize: 20,
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {Math.round(activityLog.timeElapsedSeconds / 60)}m
          </div>
          <div style={{ fontSize: 12, color: "#000" }}>writing time</div>
        </div>
      </div>
    </div>
  );
}
