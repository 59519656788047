import { gql, useQuery, useMutation } from "@apollo/client";
import { useUserInfo } from "../hooks/useUserInfo";
import { DraftMetadata } from "../gql/graphql";
import { useMemo } from "react";
import { decryptSymmetricString } from "../crypto/utils";
import { formatDistanceToNow } from "../utils/string";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { FilePlus2 } from "lucide-react";
import {
  getUnauthedDraftData,
  setUnauthedDraftData,
} from "../utils/unauthedLocalStorage";
import { Tooltip } from "./primitives/Tooltip";

type DecryptedDraftMetadata = {
  draftID: string;
  title: string;
  updatedAt: Date;
};

const GET_ALL_DRAFTS = gql`
  query GetAllDrafts {
    getAllDrafts {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
    }
  }
`;

const CREATE_DRAFT_AND_ROOM = gql`
  mutation CreateDraftAndRoom {
    createDraftAndRoom {
      draftID
      roomID
    }
  }
`;

const CREATE_ROOM_FOR_DRAFT = gql`
  mutation CreateRoomForDraft {
    createRoomForDraft {
      resourceID
    }
  }
`;

export default function DraftList() {
  const { decryptionKey, isLoggedIn } = useUserInfo();
  const navigate = useNavigate();
  const [createDraftAndRoom] = useMutation(CREATE_DRAFT_AND_ROOM);
  const [createRoomForDraft] = useMutation(CREATE_ROOM_FOR_DRAFT);

  const { data } = useQuery(GET_ALL_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  const draftMetadataList = useMemo<DecryptedDraftMetadata[]>(() => {
    if (!isLoggedIn) {
      const unauthedDraftData = getUnauthedDraftData();
      if (unauthedDraftData) {
        return [
          {
            draftID: "new",
            title: "New draft",
            updatedAt: new Date(),
          },
        ];
      }

      return [];
    }
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getAllDrafts.drafts.map((metadata: DraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
        updatedAt: new Date(metadata.updatedAt),
      };
    });
  }, [data, decryptionKey, isLoggedIn]);

  const handleCreateDraftAndRoom = async () => {
    try {
      if (!isLoggedIn) {
        // just go to the /new route
        const { data } = await createRoomForDraft();
        // have to set it to something otherwise we can't tell if a draft was ever made
        setUnauthedDraftData("<p></p>");
        if (data?.createRoomForDraft?.resourceID) {
          navigate(`/room/${data.createRoomForDraft.resourceID}/draft/new`);
        }
      }
      const { data } = await createDraftAndRoom();
      if (data?.createDraftAndRoom?.draftID) {
        navigate(
          `/room/${data.createDraftAndRoom.roomID}/draft/${data.createDraftAndRoom.draftID}ƒ`
        );
      }
    } catch (error) {
      console.error("Error creating draft and room:", error);
    }
  };

  const handleDraftClick = async (draftID: string) => {
    try {
      const { data } = await createRoomForDraft();
      if (data?.createRoomForDraft?.resourceID) {
        navigate(
          `/room/${data.createRoomForDraft.resourceID}/draft/${draftID}`
        );
      }
    } catch (error) {
      console.error("Error creating room for draft:", error);
    }
  };

  const userCanCreateDraft = useMemo(() => {
    if (isLoggedIn) {
      return true;
    }
    const unauthedDraftData = getUnauthedDraftData();
    return !unauthedDraftData;
  }, [isLoggedIn]);

  return (
    <div style={{ padding: 50 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p
          className="font-sans"
          style={{ fontSize: 20, fontWeight: "bold", marginBottom: 20 }}
        >
          Your drafts
        </p>
        <Tooltip
          text={"Create an account to manage multiple drafts!"}
          active={!userCanCreateDraft}
        >
          <Button
            size="md"
            color="primary"
            className="font-sans"
            onClick={handleCreateDraftAndRoom}
            startContent={<FilePlus2 />}
            isDisabled={!userCanCreateDraft}
          >
            <p style={{ fontSize: 14}}>New draft</p>
          </Button>
        </Tooltip>
      </div>
      <div>
        {draftMetadataList.map((draft) => (
          <div
            key={draft.draftID}
            className="font-sans"
            style={{
              display: "flex",
              alignItems: "baseline",
              marginBottom: 12,
              fontSize: 14,
              cursor: "pointer",
            }}
            onClick={() => handleDraftClick(draft.draftID)}
          >
            <span style={{ fontWeight: "bold" }}>
              {draft.title || "Untitled"}
            </span>
            <div
              style={{
                flex: 1,
                borderBottom: "1px dotted #d4d4d8",
                margin: "0 8px",
                position: "relative",
                top: -4,
              }}
            />
            <span style={{ color: "#6B7280" }}>
              {formatDistanceToNow(draft.updatedAt)} ago
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
