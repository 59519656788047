import { Suspense, useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FriendStatus, UserMetadata } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CircularProgress,
  Modal,
  ModalContent,
  Switch,
} from "@nextui-org/react";
import RequestButton from "../components/RequestButton";
import ActivityGrid from "../components/ActivityGrid";
import { useMediaQuery } from "react-responsive";
import Sundial from "../components/Sundial";
import ProfileActivityFeed from "../components/ProfileActivityFeed";
import HomepageHeader from "../components/HomepageHeader";
import { formatNumber, getCounterColor } from "../utils/string";
import ProfileAvatar from "../components/ProfileAvatar";

const GET_OTHER_USER_INFO = gql`
  query GetOtherUserInfo($username: String!) {
    getOtherUserInfo(request: { username: $username }) {
      profilePhotoURL
      friendStatus
      firstName
      lastName
      bio
      link
      private
      friendCount
      friends {
        username
        profilePhotoURL
        profilePlaceholder
      }
      percentWordsThisWeek
      percentWordsAllTime
      sundialScore
      totalWordsAdded
      profilePlaceholder
    }
  }
`;

const GET_SELF_PROFILE_INFO = gql`
  query GetSelfProfileInfo {
    getSelfProfileInfo {
      friends {
        username
        profilePhotoURL
        profilePlaceholder
      }
      percentWordsThisWeek
      percentWordsAllTime
      sundialScore
      totalWordsAdded
      profilePlaceholder
    }
  }
`;

function Profile() {
  const { username } = useParams();
  const isMobile = useMediaQuery({
    query: "(max-width: 1060px)",
  });
  const [getOtherUserInfo, { data: otherUserData }] = useLazyQuery(
    GET_OTHER_USER_INFO,
    {
      fetchPolicy: "no-cache",
      variables: { username },
    }
  );
  const { loading } = useUserInfo();

  const [getSelfProfileInfo] = useLazyQuery(GET_SELF_PROFILE_INFO, {
    fetchPolicy: "no-cache",
  });
  const [hoveringFriendUsername, setHoveringFriendUsername] = useState<
    string | null
  >(null);
  const [showFriendPane, setShowFriendPane] = useState(false);
  const [otherUserInfoState, setOtherUserInfoState] = useState<{
    friendStatus: FriendStatus | null;
    private: boolean;
    friendCount: number;
    friends: UserMetadata[];
    link?: string;
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
    totalWordsAdded: number;
    profilePlaceholder: string;
  }>({
    friendStatus: null,
    private: false,
    friendCount: 0,
    friends: [],
    percentWordsThisWeek: 0,
    percentWordsAllTime: 0,
    sundialScore: 0,
    totalWordsAdded: 0,
    profilePlaceholder: "",
  });
  const [selfInfoState, setSelfInfoState] = useState<{
    friends: UserMetadata[];
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
    totalWordsAdded: number;
    profilePlaceholder: string;
  }>({
    friends: [],
    percentWordsThisWeek: 0,
    percentWordsAllTime: 0,
    sundialScore: 0,
    totalWordsAdded: 0,
    profilePlaceholder: "",
  });
  const {
    profilePhotoDownloadURL,
    username: reduxUsername,
    firstName,
    lastName,
    bio,
    link,
    isLoggedIn,
  } = useUserInfo();
  const navigate = useNavigate();
  const isSelf = useMemo(() => {
    return username === reduxUsername;
  }, [reduxUsername, username]);
  const shouldShowFollowers = useMemo<boolean>(
    () =>
      otherUserInfoState?.friendStatus === FriendStatus.Active ||
      !otherUserInfoState.private ||
      isSelf,
    [isSelf, otherUserInfoState?.friendStatus, otherUserInfoState.private]
  );

  useEffect(() => {
    if (!isSelf) {
      getOtherUserInfo({
        onCompleted: (data) => {
          setOtherUserInfoState({
            friendStatus: data.getOtherUserInfo.friendStatus,
            private: data.getOtherUserInfo.private,
            friendCount: data.getOtherUserInfo.friendCount,
            friends: data.getOtherUserInfo.friends ?? [],
            link: data.getOtherUserInfo.link,
            percentWordsThisWeek: data.getOtherUserInfo.percentWordsThisWeek,
            percentWordsAllTime: data.getOtherUserInfo.percentWordsAllTime,
            sundialScore: data.getOtherUserInfo.sundialScore,
            totalWordsAdded: data.getOtherUserInfo.totalWordsAdded,
            profilePlaceholder: data.getOtherUserInfo.profilePlaceholder,
          });
        },
        onError: () => {
          navigate("/");
        },
      });
    } else {
      getSelfProfileInfo({
        onCompleted: (data) => {
          setSelfInfoState({
            friends: data.getSelfProfileInfo.friends,
            percentWordsThisWeek: data.getSelfProfileInfo.percentWordsThisWeek,
            percentWordsAllTime: data.getSelfProfileInfo.percentWordsAllTime,
            sundialScore: data.getSelfProfileInfo.sundialScore,
            totalWordsAdded: data.getSelfProfileInfo.totalWordsAdded,
            profilePlaceholder: data.getSelfProfileInfo.profilePlaceholder,
          });
        },
      });
    }
  }, [getOtherUserInfo, getSelfProfileInfo, isSelf, navigate, username]);

  function MobileHeader() {
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        <div className="flex flex-row items-center">
          <ProfileAvatar
            src={
              isSelf
                ? profilePhotoDownloadURL ?? ""
                : otherUserData?.getOtherUserInfo.profilePhotoURL ?? ""
            }
            showFallback
            style={{ width: 100, height: 100, marginRight: 20 }}
            profilePlaceholder={
              isSelf
                ? selfInfoState.profilePlaceholder
                : otherUserData?.getOtherUserInfo.profilePlaceholder
            }
          />
          <div>
            {isSelf && firstName && (
              <div className="mt-2">
                <p
                  className="font-sans"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {firstName} {lastName}
                </p>
              </div>
            )}
            {!isSelf && otherUserData?.getOtherUserInfo.firstName && (
              <div className="mt-2">
                <p
                  className="font-sans"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {otherUserData?.getOtherUserInfo.firstName}{" "}
                  {otherUserData?.getOtherUserInfo.lastName}
                </p>
              </div>
            )}
            <div className="mt-2">
              <p className="font-sans" style={{ fontSize: 20 }}>
                {username}
              </p>
            </div>
            {!isSelf && (
              <RequestButton
                setOtherUserInfoState={setOtherUserInfoState}
                otherUserInfoState={otherUserInfoState}
              />
            )}

            <div className="w-full" style={{ marginTop: 16 }}>
              <div className="flex flex-row w-full">
                <div
                  className="font-sans"
                  style={{
                    cursor: shouldShowFollowers ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (shouldShowFollowers) {
                      setShowFriendPane(true);
                    }
                  }}
                >
                  <p style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: "bold" }}>
                      {isSelf
                        ? selfInfoState.friends.length
                        : otherUserInfoState.friendCount}
                    </span>{" "}
                    friend
                    {isSelf
                      ? selfInfoState.friends.length === 1
                        ? ""
                        : "s"
                      : otherUserInfoState.friendCount === 1
                      ? ""
                      : "s"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-row w-full justify-between font-sans"
          style={{ marginTop: 16 }}
        >
          <div className="flex flex-1 flex-col items-center">
            <div
              className="flex items-center justify-center"
              style={{ height: 48, width: 78 }}
            >
              <p
                style={{
                  fontSize: 30,
                  fontFamily: "Nothing you could do",
                  fontWeight: "bold",
                  color: getCounterColor(
                    isSelf
                      ? selfInfoState.totalWordsAdded
                      : otherUserInfoState.totalWordsAdded
                  ),
                }}
              >
                {formatNumber(
                  isSelf
                    ? selfInfoState.totalWordsAdded
                    : otherUserInfoState.totalWordsAdded
                )}
              </p>
            </div>
            <div style={{ marginTop: 4 }}>
              <p
                style={{
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                draft zero words
              </p>
            </div>
          </div>
          <div className="flex-1">
            <Sundial
              sundialScore={
                isSelf
                  ? selfInfoState.sundialScore
                  : otherUserData?.getOtherUserInfo.sundialScore ?? 0
              }
            />
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          <p className="font-sans font-semibold">Activity grid</p>{" "}
        </div>
        <ActivityGrid />
        <div style={{ marginTop: 30 }}>
          <p className="font-sans font-semibold text-lg">Recent sessions</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return null;
  }

  return (
    <div>
      <HomepageHeader />
      {isMobile && (
        <div className="flex flex-col w-full">
          <div
            className="flex flex-col items-start"
            style={{
              width: "100%",
            }}
          >
            {(isSelf ||
              !otherUserInfoState.private ||
              otherUserInfoState.friendStatus === FriendStatus.Active) && [
              <MobileHeader />,
              <div style={{ margin: 20 }}>
                <ProfileActivityFeed />
              </div>,
            ]}
            {!isSelf &&
              otherUserInfoState.private &&
              otherUserInfoState.friendStatus !== FriendStatus.Active && (
                <>
                  <MobileHeader />
                  <div style={{ padding: 20 }}>
                    <p className="font-sans" style={{ color: "#aaaaaa" }}>
                      This user's profile is private. Become friends to see
                      their writing sessions.
                    </p>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="flex flex-row w-full justify-center relative">
          {/* Profile pane */}
          <div className="flex flex-row justify-end">
            <div
              style={{
                margin: 20,
                marginRight: 64,
                width: 200,
                marginLeft: 64,
              }}
            >
              <ProfileAvatar
                src={
                  isSelf
                    ? profilePhotoDownloadURL ?? ""
                    : otherUserData?.getOtherUserInfo.profilePhotoURL ?? ""
                }
                profilePlaceholder={
                  isSelf
                    ? selfInfoState.profilePlaceholder
                    : otherUserData?.getOtherUserInfo.profilePlaceholder
                }
                style={{ width: 200, height: 200 }}
              />
              {isSelf && firstName && (
                <div className="mt-2">
                  <p
                    className="font-sans"
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    {firstName} {lastName}
                  </p>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.firstName && (
                <div className="mt-2">
                  <p
                    className="font-sans"
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    {otherUserData?.getOtherUserInfo.firstName}{" "}
                    {otherUserData?.getOtherUserInfo.lastName}
                  </p>
                </div>
              )}
              <div className="mt-2">
                <p className="font-sans" style={{ fontSize: 20 }}>
                  {username}
                </p>
              </div>
              {isSelf && link && (
                <div className="mt-2">
                  <a href={link}>
                    <p
                      className="font-sans"
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                        color: "#7194A8",
                      }}
                    >
                      {link}
                    </p>
                  </a>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.link && (
                <div className="mt-2">
                  <a href={otherUserData?.getOtherUserInfo.link}>
                    <p
                      className="font-sans"
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                        color: "#7194A8",
                      }}
                    >
                      {otherUserData?.getOtherUserInfo.link}
                    </p>
                  </a>
                </div>
              )}
              {isSelf && bio && (
                <div className="mt-2">
                  <p className="font-sans" style={{ fontSize: 14 }}>
                    {bio}
                  </p>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.bio && (
                <div className="mt-2">
                  <p className="font-sans" style={{ fontSize: 16 }}>
                    {otherUserData?.getOtherUserInfo.bio}
                  </p>
                </div>
              )}
              <div style={{ marginTop: 16 }}>
                {isSelf && (
                  <div>
                    <Button
                      className="font-sans w-full h-8"
                      onClick={() => navigate("/settings")}
                    >
                      Settings
                    </Button>
                  </div>
                )}
                {!isSelf && isLoggedIn && (
                  <RequestButton
                    setOtherUserInfoState={setOtherUserInfoState}
                    otherUserInfoState={otherUserInfoState}
                  />
                )}
              </div>
              <div className="w-full" style={{ marginTop: 16 }}>
                <div className="flex flex-row w-full">
                  <div
                    className="font-sans"
                    style={{
                      cursor: shouldShowFollowers ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (shouldShowFollowers) {
                        setShowFriendPane(true);
                      }
                    }}
                  >
                    <p style={{ fontSize: 16 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {isSelf
                          ? selfInfoState.friends.length
                          : otherUserInfoState.friendCount}
                      </span>{" "}
                      friend
                      {isSelf
                        ? selfInfoState.friends.length === 1
                          ? ""
                          : "s"
                        : otherUserInfoState.friendCount === 1
                        ? ""
                        : "s"}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row w-full justify-between font-sans"
                style={{ marginTop: 16 }}
              >
                <div className="flex flex-1 flex-col items-center">
                  <div
                    className="flex items-center justify-center"
                    style={{ height: 48, width: 78 }}
                  >
                    <p
                      style={{
                        fontSize: 30,
                        fontFamily: "Nothing you could do",
                        fontWeight: "bold",
                        color: getCounterColor(
                          isSelf
                            ? selfInfoState.totalWordsAdded
                            : otherUserInfoState.totalWordsAdded
                        ),
                      }}
                    >
                      {formatNumber(
                        isSelf
                          ? selfInfoState.totalWordsAdded
                          : otherUserInfoState.totalWordsAdded
                      )}
                    </p>
                  </div>
                  <div style={{ marginTop: 4 }}>
                    <p
                      style={{
                        fontSize: 12,
                        textAlign: "center",
                      }}
                    >
                      draft zero words
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <Sundial
                    sundialScore={
                      isSelf
                        ? selfInfoState.sundialScore
                        : otherUserData?.getOtherUserInfo.sundialScore ?? 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Activity feed pane */}
          <div className="flex flex-col flex-1 items-start font-sans">
            <div
              className="flex flex-col items-center"
              style={{ width: "100%" }}
            >
              {(isSelf ||
                !otherUserInfoState.private ||
                otherUserInfoState.friendStatus === FriendStatus.Active) && (
                <div style={{ marginTop: 30 }}>
                  <div style={{ marginBottom: 20 }}>
                    <p
                      className="font-sans font-semibold"
                      style={{ fontSize: 20 }}
                    >
                      Recent sessions
                    </p>
                  </div>
                  <ProfileActivityFeed />
                </div>
              )}
              {!isSelf &&
                otherUserInfoState.private &&
                otherUserInfoState.friendStatus !== FriendStatus.Active && (
                  <div style={{ marginTop: 30 }}>
                    <div style={{ marginBottom: 20 }}>
                      <p
                        className="font-sans font-semibold"
                        style={{ fontSize: 20 }}
                      >
                        Recent sessions
                      </p>
                    </div>
                    <p className="font-sans" style={{ color: "#aaaaaa" }}>
                      This user's profile is private. Become friends to see
                      their writing sessions.
                    </p>
                  </div>
                )}
            </div>
          </div>
          <div style={{ marginRight: 64, marginLeft: 64 }}>
            <div
              className="flex flex-row justify-between w-full"
              style={{
                marginBottom: 20,
                marginTop: 30,
              }}
            >
              <p className="font-sans font-semibold" style={{ fontSize: 20 }}>
                Activity grid
              </p>
            </div>
            <div
              className="font-sans"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 16,
                marginBottom: 6,
                width: "100%",
                color: "#cccccc",
                fontWeight: "bold",
              }}
            >
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>Su</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>M</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>Tu</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>W</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>Th</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>F</p>
              </div>
              <div
                className="flex flex-row justify-center"
                style={{ width: 32 }}
              >
                <p>Sa</p>
              </div>
            </div>
            <ActivityGrid vertical={true} />
          </div>
        </div>
      )}
      <Modal
        isOpen={showFriendPane}
        onClose={() => setShowFriendPane(false)}
        className="max-h-[90vh] overflow-y-auto"
      >
        <ModalContent className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col w-full" style={{ padding: 20 }}>
            <div
              className="flex flex-row justify-between w-full"
              style={{ marginBottom: 20 }}
            >
              <p className="font-sans font-semibold" style={{ fontSize: 20 }}>
                Friends
              </p>
            </div>
            <div className="flex flex-col w-full">
              {(isSelf ? selfInfoState : otherUserInfoState).friends.map(
                (f) => (
                  <div
                    className="flex flex-row w-full cursor-pointer items-center"
                    onClick={() => {
                      setShowFriendPane(false);
                      navigate(`/writer/${f.username}`);
                    }}
                    key={f.username}
                    onMouseEnter={() => setHoveringFriendUsername(f.username)}
                    onMouseLeave={() => setHoveringFriendUsername(null)}
                    style={{
                      backgroundColor:
                        hoveringFriendUsername === f.username
                          ? "#f0f0f0"
                          : "transparent",
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    <ProfileAvatar
                      src={f.profilePhotoURL ?? ""}
                      profilePlaceholder={f.profilePlaceholder}
                      showFallback
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                    <p>{f.username}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Profile;
